<template>
  <div>
    <div class="listRankTitle" v-if="needRankTitle">
      <div
        v-for="(item, key) in rankCycle"
        :key="key"
        @click="chooseRankType(item)"
        :class="item == curRankCycle ? 'banganStyAcvive' : ''"
      >
        {{ item }}
      </div>
    </div>
    <div class="rankingBody boxshow">
      <div ref="descTitle" class="rankingHeader">
        <div v-if="!(columns[0] && columns[0].selfLine)" class="headerBox">
          <span v-if="columns[0] && columns[0].name">
            {{ columns[0].name }}
          </span>
          <span v-else>
            {{ columns[0] }}
          </span>
        </div>
        <div
          class="headerBox"
          :style="{ width: columns.length <= 3 ? '40%' : '30% !important' }"
          v-for="(column, key) in columns.slice(1)"
          :key="key"
          @click="sortByKey(column)"
        >
          <span v-if="column.name">
            {{ column.name }}
          </span>
          <span v-else>
            {{ column }}
          </span>
        </div>
      </div>

      <div class="lineBody" :style="{ height: listHeight }">
        <div v-if="columns[0] && columns[0].selfLine">
          <div
            v-for="(item, index) in showList"
            :key="index"
            class="rankingLine rankingLineSelf"
          >
            <div
              style="text-align: left; color: #aeacac"
              :class="
                columns[0] && columns[0].selfLine
                  ? 'headerBoxSelf titleOverFlow'
                  : 'headerBoxTitle titleOverFlow'
              "
            >
              <span v-if="columns[0] && columns[0].name">
                {{ item[columns[0]["value"]] || 0 }}
              </span>
              <span v-else>
                {{ item[columns[0]] || 0 }}
              </span>
            </div>
            <div style="display: flex">
              <div
                :style="{
                  padding: '4px 0',
                }"
                v-for="(column, columnIndex) in columns.slice(1)"
                :key="columnIndex"
                :class="`mainTitleRank${columnIndex + 1} mainTitleRankFlex`"
                @click="chooseItem(item)"
              >
                <div>
                  <span v-if="column.name">
                    <span
                      v-if="column.value && column.value.indexOf('环比') > -1"
                    >
                      <span
                        class="tipsHuanbiupColor"
                        v-if="
                          item[column.value] &&
                          item[column.value].indexOf('+') > -1
                        "
                      >
                        {{ item[column.value] || 0 }}
                      </span>
                      <span v-else class="tipsHuanbiDownColor">
                        {{ item[column.value] || 0 }}
                      </span>
                    </span>
                    <span v-else>
                      {{ item[column.value] || 0 }}
                    </span>
                  </span>
                  <span v-else>
                    {{ item[columns[columnIndex + 1]] || 0 }}
                  </span>
                </div>
              </div>
            </div>

            <span v-if="index + 1 <= 10" class="number bg01">{{
              index + 1
            }}</span>
            <span
              v-if="index + 1 <= 50 && index + 1 > 10"
              class="number bg02"
              >{{ index + 1 }}</span
            >
            <span
              v-if="index + 1 <= 100 && index + 1 > 50"
              class="number bg03"
              >{{ index + 1 }}</span
            >
            <span
              v-if="index + 1 <= 200 && index + 1 > 100"
              class="number bg04"
              >{{ index + 1 }}</span
            >
          </div>
        </div>

        <div v-else>
          <div
            v-for="(item, index) in showList"
            :key="index"
            class="rankingLine"
          >
            <div
              :class="
                columns[0] && columns[0].selfLine
                  ? 'headerBoxSelf titleOverFlow'
                  : 'headerBoxTitle titleOverFlow'
              "
            >
              <span v-if="columns[0] && columns[0].name">
                {{ item[columns[0]["value"]] || 0 }}
              </span>
              <span v-else>
                {{ item[columns[0]] || 0 }}
              </span>
            </div>
            <div
              :style="{
                padding: '4px 0',
              }"
              v-for="(column, columnIndex) in columns.slice(1)"
              :key="columnIndex"
              :class="`mainTitleRank${columnIndex + 1} mainTitleRankFlex`"
              @click="chooseItem(item)"
            >
              <div>
                <span v-if="column.name">
                  <span
                    v-if="column.value && column.value.indexOf('环比') > -1"
                  >
                    <span
                      class="tipsHuanbiupColor"
                      v-if="
                        item[column.value] &&
                        item[column.value].indexOf('+') > -1
                      "
                    >
                      {{ item[column.value] || 0 }}
                    </span>
                    <span v-else class="tipsHuanbiDownColor">
                      {{ item[column.value] || 0 }}
                    </span>
                  </span>
                  <span v-else>
                    {{ item[column.value] || 0 }}
                  </span>
                </span>
                <span v-else>
                  {{ item[columns[columnIndex + 1]] || 0 }}
                </span>
              </div>
            </div>

            <span v-if="index + 1 <= 10" class="number bg01">{{
              index + 1
            }}</span>
            <span
              v-if="index + 1 <= 50 && index + 1 > 10"
              class="number bg02"
              >{{ index + 1 }}</span
            >
            <span
              v-if="index + 1 <= 100 && index + 1 > 50"
              class="number bg03"
              >{{ index + 1 }}</span
            >
            <span
              v-if="index + 1 <= 200 && index + 1 > 100"
              class="number bg04"
              >{{ index + 1 }}</span
            >
          </div>
        </div>

        <div
          v-if="showList.length == 0 && !showLoad"
          style="
            width: 100%;
            padding: 10px 0;
            text-align: center;
            color: #795548;
          "
        >
          没有更多数据了
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";

export default {
  name: "listCom",
  components: {},
  data() {
    return {
      curRankCycle: "",
      listHeight: "74vh",
    };
  },
  props: {
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    columnsRank: {
      type: Array,
      default: () => {
        return [];
      },
    },
    //榜单周期
    rankCycle: {
      type: Array,
      default: () => {
        return ["周榜", "月榜"];
      },
    },
    needRankTitle: {
      type: Boolean,
      default: true,
    },

    // 默认榜单周期
    defaultRankCycle: {
      type: String,
      default: "周榜",
    },

    showList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    title: {
      type: String,
      default: "",
    },

    // 是否加载完成
    showLoad: {
      type: Boolean,
      default: false,
    },

    comHeight: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    getListHeight() {
      const _clientHeight =
        document.documentElement.clientHeight ||
        document.body.clientHeight ||
        window.innerHeight;

      console.log(
        this.comHeight,
        "descTitle top=----",
        "offsetTop:",
        this.$refs.descTitle.offsetTop,
        "clientHeight:",
        this.$refs.descTitle.clientHeight,
        "offsetHeight:",
        this.$refs.descTitle.offsetHeight,

        _clientHeight,
        parseFloat(this.$refs.descTitle.offsetTop)
      );
      if (_clientHeight && parseFloat(_clientHeight)) {
        const listHeight =
          ((parseFloat(_clientHeight) -
            (parseFloat(this.$refs.descTitle.offsetTop) +
              parseFloat(this.$refs.descTitle.clientHeight))) /
            parseFloat(_clientHeight)) *
          100;

        // console.log("descTitle top=----", listHeight);

        if (listHeight) {
          this.listHeight = `${parseFloat(listHeight) - this.comHeight}vh`;
        }
      }
    },
    // 更换榜单
    chooseRankType(type) {
      this.curRankCycle = type;
      this.getListHeight();
      this.$emit("chooseRankType", type);
    },
    chooseItem(data) {
      this.$emit("chooseListItem", data);
    },
    sortByKey(data) {
      if (data && data.sort) {
        // this.$emit("sortByKey", data);
      }
    },
  },
  async created() {
    if (this.defaultRankCycle) {
      this.curRankCycle = this.defaultRankCycle;
    }
  },
  mounted() {
    this.getListHeight();
  },
  watch: {
    comHeight: function (val) {
      console.log("----");
      this.getListHeight();
    },
  },
};
</script>

<style scoped>
.listRankTitle {
  text-align: center;
  font-size: 23px;
  color: #fff;
  font-weight: 500;
  display: flex;
  /* height: 35px; */
  flex-wrap: wrap;
  line-height: 35px;
  font-size: 14px;
  border-radius: 4px;
  justify-content: center;
  margin: 10px 0;
}

.listRankTitle .banganStyAcvive {
  /* background: rgb(51, 124, 153); */
  /* background: #6f757f; */
  background: #386ba1;
  font-weight: bold;
  opacity: 1;
}
.listRankTitle div {
  opacity: 0.8;
  background: #b0b3b9;
  padding: 0px 18px;
  box-shadow: 0px 0px 9px 0 rgba(29, 32, 33, 0.28);
}

.rankingBody {
  height: 85vh;
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
}
.rankingBody .rankingHeader {
  width: 98%;
  display: flex;
  background: #fff;
  box-shadow: 0 0 10px rgba(125, 125, 125, 0.17);
  margin: 0 auto;
  border-radius: 6px;
  padding: 8px 10px;
  color: #6e7482;
  font-weight: bold;
}

.rankingBody .headerBox {
  flex: 1;
  position: relative;
  font-size: 14px;
  text-align: center;
}

.rankingBody .headerBoxSelf {
  text-align: left;
  width: 100%;
  position: relative;
  font-size: 14px;
  text-align: center;
}

.rankingBody .titleHeaderBox {
  width: 30%;
  position: relative;
  font-size: 14px;
  text-align: center;
}
.boxshow {
  box-shadow: 0px 0px 10px rgba(208, 35, 36, 0.05);
}

.lineBody {
  width: 100%;
  height: calc(100% - 10px);
  overflow-y: scroll;
}
.rankingLine {
  width: 98%;
  display: flex;
  /* justify-content: flex-end; */
  background: #fff;
  box-shadow: 0 0 10px rgba(125, 125, 125, 0.17);
  margin: 5px auto;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 8px 10px;
  /* overflow: hidden; */
  position: relative;
}

.rankingLineSelf {
  display: block;
}

.rankingLine > div {
  margin-top: 5px;
  overflow: hidden;
  /* text-overflow: ellipsis;
  white-space: nowrap; */
  text-align: center;
  /* vertical-align: top; */
  /* flex: 1; */
}
.rankingLine > .number {
  display: inline-block;
  padding: 0 3px;
  /* width: 20px; */
  height: 15px;
  border-bottom-right-radius: 6px;
  font-size: 13px;
  line-height: 15px;
  /* background: rgb(86, 127, 31); */
  /* background: #757575; */
  /* background: #c75833; */
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
}
.rankingLine > .numberGrey {
  background: #757575;
}

.bg01 {
  background: rgb(202, 9, 21);
}
.bg02 {
  background: rgb(252, 103, 46);
}
.bg03 {
  background: rgb(176, 194, 40);
}
.bg04 {
  background: rgb(105, 175, 120);
}
.banganSty {
  min-width: 120px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 6%;
  font-size: 14px;
  background: #1284fd;
  border-radius: 4px;
}
.banganSty .banganStyAcvive {
  background: #3362c7;
  font-weight: bold;
  opacity: 1;
  font-size: 16px;
}
.banganSty div {
  flex: 1;
  opacity: 0.8;
  padding: 0px 10px;
  box-shadow: 0px 0px 9px 0 rgba(29, 32, 33, 0.28);
}

.headerBoxTitle {
  width: 30%;
  padding: 4px 0;
}
</style>
